import { Tag } from '../../../../shared/catalogo/producto/ficha/Tags/Tags'
import { NeumaticoMotoInfo } from '../types'
import { t } from '../../../i18n'
import _ from 'lodash'

export const generateNeumaticoMotoTags = (
  neumatico: NeumaticoMotoInfo
): Tag[] => {
  return [
    neumatico.gama && {
      label: neumatico.gama,
      tooltip: t('catalogo.producto.ficha.section_tags.tooltip_moto_gama', {
        gama: neumatico.gama,
      }),
    },
    neumatico.tipo && {
      label: neumatico.tipo,
      tooltip: t('catalogo.producto.ficha.section_tags.tooltip_moto_tipo', {
        tipo: neumatico.tipo,
      }),
    },
    neumatico.aplicacion && {
      label: _.startCase(_.toLower(neumatico.aplicacion)),
      tooltip: t(
        'catalogo.producto.ficha.section_tags.tooltip_moto_aplicacion',
        {
          aplicacion: neumatico.aplicacion,
        }
      ),
    },
  ].filter(Boolean)
}
