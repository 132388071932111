import React from 'react'
import styles from './montaje.module.scss'
import MontageIcon from './assets/eina_icon.svg'
import { ServiciosMontaje } from '../../../../../common/components/FichasNeumaticos/types'
import { intl, t } from '../../../../../common/i18n'

interface Props {
  servicios?: ServiciosMontaje
}

const Montaje: React.FC<Props> = ({ servicios }) => {
  if (!servicios || servicios.length === 0 || servicios[0] === null) {
    return null
  }

  const precios = servicios
    .map((servicio) => servicio.precio)
    .filter((p) => p !== null && p !== undefined)

  if (precios.length === 0) {
    return null
  }

  const precio = Math.min(...servicios.map((servicio) => servicio.precio))

  return (
    <div className={styles.wrapper}>
      <div className={styles.price_header}>
        <MontageIcon />
        <span className={styles.price_label}>
          &nbsp;{t('catalogo.producto.ficha.montaje')}
        </span>
        <span className={styles.price_text}>
          &nbsp;
          {intl.formatNumber(precio, {
            style: 'currency',
            currency: 'EUR',
          })}
        </span>
      </div>
    </div>
  )
}

export default Montaje
