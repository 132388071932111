import { graphql, PageProps } from 'gatsby'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import Layout from '../../components/Layout'
import { locale, t, textWithLocale } from '../../i18n'
import route from '../../utils/route'
import { makeNeumaticosSchema } from '../../utils/schema'
import {
  getModeloImagenes,
  getModeloMarca,
  getNeumaticoMotoSpecs,
  priceWithTax,
  productoActivo,
} from '../../components/FichasNeumaticos/utils'
import { useSelectedMenu } from '../../../../sites/rodi/helpers/customHooks/useSelectedMenu'
import { MenuPrincipal } from '../../../../sites/rodi/constants/menu'
import { RequestTyreMotoData } from '../../components/RequestTyre/RequestTyreMoto'
import CatalogoFicha from '../../../shared/catalogo/producto/ficha/CatalogoFicha'
import WhiteBoxContainer from '../../../shared/components/WhiteBoxContainer'
import _ from 'lodash'
import { getMotoIconPath } from '../../components/FichasNeumaticos/utilsIconoMoto'
import { generateNeumaticoMotoTags } from '../../components/FichasNeumaticos/Tags/generateNeumaticoMotoTags'
import useUpdatePromocion from '../../helpers/customHooks/useUpdatePromocion'
import { getFetchFunction } from '../../../shared/catalogo/producto/ficha/promocion/getFetchFunction'
import { CatalogoProductInfo } from '../../../shared/catalogo/producto/ficha/CatalogoProductInfo'
import { CategoriaNeumatico } from '../../types/Neumatico'
import ComprarProductoCTA from '../../../shared/catalogo/producto/ficha/cta/ComprarProductoCTA'
import Montaje from '../../../shared/catalogo/producto/ficha/cta/Montaje'
import { Product } from '../../context/reducers/cestaReducer'
import { productInfoFromId } from '../../api/cesta'
import AppContext from '../../context/context'
import { initialNeumaticoMotoOptions } from '../../components/cesta/cestaItem/CestaItem'
import RequestMotoTyreCTA from '../../components/FichasNeumaticos/Ctas/RequestMotoTyreCTA'
import RequestTyre from '../../components/RequestTyre/RequestTyre'
import Modal from '../../../shared/components/Modal/Modal'
import logic from '../../logic'

export default function FichaNeumaticosMotoPage({
  data: {
    modeloInfo,
    neumaticoInfo,
    seoData,
    banners_es_desktop,
    banners_ca_desktop,
    banners_es_mobile,
    banners_ca_mobile,
  },
}: PageProps<Queries.FichaNeumaticosMotoPageQuery>) {
  useSelectedMenu(MenuPrincipal.NEUMATICOS)

  const {
    modelo,
    marca: { nombre: marca },
    imagenes,
    slug,
  } = modeloInfo

  const banners = {
    ca_desktop: banners_ca_desktop.nodes,
    ca_mobile: banners_ca_mobile.nodes,
    es_desktop: banners_es_desktop.nodes,
    es_mobile: banners_es_mobile.nodes,
  }

  const { userInfo } = useContext(AppContext)
  const { fichas } = getModeloImagenes(imagenes)

  const [showModal, setShowModal] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)
  const [selectedNeumatico, setSelectedNeumatico] = useState(
    null as RequestTyreMotoData | null
  )
  const [productWithInfo, setProductWithInfo] = useState({} as Product)

  useEffect(() => {
    productInfoFromId(neumaticoInfo.id_site_producto.toString(), userInfo).then(
      (product) => {
        setProductWithInfo(product)
      }
    )
  }, [])

  const updatedNeumaticoInfo = useMemo(() => {
    const mergedProduct = {
      ...neumaticoInfo.producto,
      ...productWithInfo,
    }

    return {
      ...neumaticoInfo,
      producto: {
        ...mergedProduct,
        disponibilidad:
          mergedProduct.disponibilidad ?? productoActivo(mergedProduct), // aqui si nos ha devuelto algo raro productWithInfo no tendremos el campo disponibilidad de DB, asi que miramos con activo_rodi/ep de NeumaticoProducto
        [`descripcion_${locale}`]: getNeumaticoMotoSpecs(neumaticoInfo),
      },
    }
  }, [neumaticoInfo, productWithInfo])

  const closeModal = () => {
    setSuccessMessage(false)
    setShowModal(false)
  }

  const updatedPromotion = useUpdatePromocion({
    id_site_marca_neumatico: modeloInfo.id_site_marca_neumatico,
    id_site_modelo_neumatico: modeloInfo.id_site_modelo_neumatico_moto,
    llanta: undefined,
    runflat: undefined,
    allseason: undefined,
    invierno: undefined,
    postCode: null,
    categoria_producto: 'moto',
    fetchFunction: getFetchFunction('neumatico'),
  })

  const [icon, setIcon] = useState(null)

  useEffect(() => {
    if (!neumaticoInfo) {
      return
    }
    const loadIcon = async () => {
      const fetchedIcon = await getMotoIconPath(
        neumaticoInfo.gama,
        neumaticoInfo.tipo,
        neumaticoInfo.aplicacion
      )
      setIcon(fetchedIcon)
    }

    loadIcon()
  }, [neumaticoInfo])

  const catalogoProductInfo: CatalogoProductInfo = {
    ...updatedNeumaticoInfo,
    ...modeloInfo,
    nombre_producto: updatedNeumaticoInfo.producto[`nombre_producto_${locale}`],
    estaciones: icon ? [icon] : [],
    productInfo: {
      id: updatedNeumaticoInfo.producto.id_navision,
      type: 'neumatico',
      categoria: modeloInfo.marca.clasificacion as CategoriaNeumatico,
      marca,
    },
  }

  const getCTAButton = () => {
    const isLoading =
      !updatedNeumaticoInfo?.producto ||
      Object.keys(productWithInfo).length === 0
    const isAvailable = updatedNeumaticoInfo?.producto?.disponibilidad > 0

    return (
      <>
        {isAvailable ? (
          <ComprarProductoCTA
            catalogoProductInfo={catalogoProductInfo}
            options={initialNeumaticoMotoOptions}
            bannerPromocion={updatedPromotion}
            montaje={
              <Montaje servicios={neumaticoInfo.producto?.servicio_montaje} />
            }
            disabled={isLoading || !isAvailable}
          />
        ) : (
          !isLoading && (
            <section>
              <WhiteBoxContainer className={'yellow'}>
                <RequestMotoTyreCTA
                  modelo={modeloInfo}
                  neumatico={updatedNeumaticoInfo}
                  openModal={setShowModal}
                  selectNeumatico={setSelectedNeumatico}
                  flexDirection={'column'}
                />
              </WhiteBoxContainer>
            </section>
          )
        )}
      </>
    )
  }

  const CTAButton = getCTAButton()

  return (
    <Layout
      selector={false}
      seoData={seoData}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        {
          display: t('bread_crumb.neumaticos'),
          link: route('neumaticos.index'),
        },
        {
          display: marca,
          link: route('neumaticos.marca', {
            marca: marca.toLowerCase(),
          }),
        },
        {
          display: modelo,
          link: route('neumaticos-moto.modelo', {
            marca: marca.toLowerCase(),
            modelo: slug,
          }),
        },
        {
          display: getNeumaticoMotoSpecs(neumaticoInfo),
        },
      ]}>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(
            makeNeumaticosSchema({
              name: `${getModeloMarca(modeloInfo)} ${getNeumaticoMotoSpecs(
                neumaticoInfo
              )}`,
              image: fichas.length && fichas[0].url,
              description: modelo[textWithLocale('recomendado_para')],
              brand: marca,
              price: priceWithTax(neumaticoInfo.producto).toString(),
            })
          ),
        }}
      />
      <CatalogoFicha
        catalogoProductInfo={catalogoProductInfo}
        specifications={[
          {
            label: t(
              'landing_neumaticos.titulos_caracteristicas_tecnicas.marca'
            ),
            value: modeloInfo.marca.nombre,
          },
          {
            label: t(
              'landing_neumaticos.titulos_caracteristicas_tecnicas.modelo'
            ),
            value: _.startCase(_.toLower(modeloInfo.modelo)),
          },
          {
            label: t(
              'landing_neumaticos.titulos_caracteristicas_tecnicas.medidas'
            ),
            value: getNeumaticoMotoSpecs(neumaticoInfo),
          },
          {
            label: t(
              'landing_neumaticos.titulos_caracteristicas_tecnicas.aplicacion'
            ),
            value: _.startCase(
              _.toLower(neumaticoInfo.aplicacion.toUpperCase())
            ),
          },
          {
            label: t(
              'landing_neumaticos.titulos_caracteristicas_tecnicas.gama'
            ),
            value: _.startCase(_.toLower(neumaticoInfo.gama)),
          },
          {
            label: t(
              'landing_neumaticos.titulos_caracteristicas_tecnicas.tipo'
            ),
            value: _.startCase(_.toLower(neumaticoInfo.tipo)),
          },
          {
            label: t(
              'landing_neumaticos.titulos_caracteristicas_tecnicas.marcaje'
            ),
            value: neumaticoInfo.marcaje,
          },
          {
            label: t(
              'landing_neumaticos.titulos_caracteristicas_tecnicas.normativa'
            ),
            value: neumaticoInfo.normativa,
          },
          {
            label: t(
              'landing_neumaticos.titulos_caracteristicas_tecnicas.radial'
            ),
            value: neumaticoInfo.radial,
          },
          {
            label: t(
              'landing_neumaticos.titulos_caracteristicas_tecnicas.flanco'
            ),
            value: neumaticoInfo.flanco,
          },
          {
            label: t(
              'landing_neumaticos.titulos_caracteristicas_tecnicas.homologacion'
            ),
            value: neumaticoInfo.homologacion,
          },
          {
            label: t(
              'landing_neumaticos.titulos_caracteristicas_tecnicas.compuesto'
            ),
            value: neumaticoInfo.compuesto,
          },
          {
            label: t(
              'landing_neumaticos.titulos_caracteristicas_tecnicas.terreno'
            ),
            value: neumaticoInfo.terreno,
          },
        ]}
        banners={banners}
        cta={CTAButton}
        tags={generateNeumaticoMotoTags(neumaticoInfo)}>
        <Modal
          title={t('landing_neumaticos.neumatico_item.disponibilidad')}
          closeModal={closeModal}
          showModal={showModal}>
          <RequestTyre
            successMessage={successMessage}
            setSuccessMessage={setSuccessMessage}
            selectedNeumatico={selectedNeumatico}
            closeModal={closeModal}
            requestAvailability={({
              email,
              selectedNeumatico,
              device,
              precio,
              idioma,
              cantidad = 1,
            }) =>
              logic.requestNeumaticoMotoAvailability(
                email,
                selectedNeumatico.marca,
                selectedNeumatico.modelo,
                selectedNeumatico.id_site_modelo_neumatico_moto,
                selectedNeumatico.id_site_producto,
                selectedNeumatico.tyreSpecs,
                device,
                precio,
                idioma,
                cantidad
              )
            }
          />
        </Modal>
      </CatalogoFicha>
    </Layout>
  )
}
export const pageQuery = graphql`
  query FichaNeumaticosMotoPage(
    $idModelo: Int!
    $idSiteProductoNeumatico: Int!
    $url: String!
    $banners_es_desktop: String!
    $banners_ca_desktop: String!
    $banners_es_mobile: String!
    $banners_ca_mobile: String!
  ) {
    modeloInfo: modelosMoto(id_site_modelo_neumatico_moto: { eq: $idModelo }) {
      ...modeloMotoInfo
      ...modeloMotoMarca
      ...modeloMotoImagen
    }
    neumaticoInfo: neumaticosMoto(
      id_site_productos_neumaticos_moto: { eq: $idSiteProductoNeumatico }
    ) {
      ...neumaticoMotoInfo
      ...productoMotoInfo
    }
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
    banners_ca_desktop: allS3Asset(
      filter: { Key: { glob: $banners_ca_desktop } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_ca_mobile: allS3Asset(
      filter: { Key: { glob: $banners_ca_mobile } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_desktop: allS3Asset(
      filter: { Key: { glob: $banners_es_desktop } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_mobile: allS3Asset(
      filter: { Key: { glob: $banners_es_mobile } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
  }
`
